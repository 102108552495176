import React, { useState } from 'react';

const PrivacyPolicyPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button
        onClick={togglePopup}
        className="text-white dark:text-gray-400 font-medium text-left hover:underline"
      >
        Privacybeleid
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-12">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg relative overflow-auto max-h-full">
            <button
              onClick={togglePopup}
              className="text-4xl absolute top-2 right-2 text-gray-500 hover:text-gray-800"
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold mb-4">Privacybeleid</h2>
            <div className="text-gray-700 space-y-4">
              <p>
                <strong>Ingangsdatum:</strong> 22/08/2024
              </p>
              <p>
                Wij zijn gevestigd in België en zijn verantwoordelijk voor de verwerking van uw persoonsgegevens in overeenstemming met de Belgische wetgeving en de Algemene Verordening Gegevensbescherming (AVG/GDPR).
              </p>
              <h3 className="text-xl font-semibold mt-4">1. Inleiding</h3>
              <p>
                Wij respecteren uw privacy en zetten ons in voor de bescherming van uw persoonsgegevens. Dit privacybeleid legt uit hoe wij uw gegevens verzamelen, gebruiken, opslaan en beschermen wanneer u onze website bezoekt of onze diensten gebruikt.
              </p>
              <h3 className="text-xl font-semibold mt-4">2. Welke gegevens verzamelen wij?</h3>
              <p>Wij kunnen de volgende soorten persoonsgegevens verzamelen:</p>
              <ul className="list-disc pl-5">
                <li>Identificatiegegevens: zoals naam, voornaam, geboortedatum, e-mailadres, telefoonnummer.</li>
                <li>Locatiegegevens: zoals IP-adres, geografische locatie op basis van IP.</li>
                <li>Gebruiksgegevens: zoals informatie over hoe u onze website en diensten gebruikt, inclusief browsertype, bezoektijden, en paginaweergaves.</li>
                <li>Financiële gegevens: zoals betalingsinformatie wanneer u aankopen doet via onze website.</li>
              </ul>
              <h3 className="text-xl font-semibold mt-4">3. Hoe verzamelen wij uw gegevens?</h3>
              <p>Wij verzamelen gegevens op de volgende manieren:</p>
              <ul className="list-disc pl-5">
                <li>Direct van u: wanneer u formulieren invult op onze website, een account aanmaakt, of aankopen doet.</li>
                <li>Automatisch: door het gebruik van cookies en vergelijkbare technologieën die uw activiteit op onze website volgen.</li>
              </ul>
              <h3 className="text-xl font-semibold mt-4">4. Waarom verzamelen wij uw gegevens?</h3>
              <p>Wij verzamelen en gebruiken uw gegevens voor de volgende doeleinden:</p>
              <ul className="list-disc pl-5">
                <li>Om onze diensten te leveren: bijvoorbeeld om uw bestellingen te verwerken en uit te voeren.</li>
                <li>Klantenservice: om te reageren op uw vragen of verzoeken.</li>
                <li>Verbetering van onze website: door analyse van gebruikspatronen en feedback.</li>
                <li>Marketingdoeleinden: indien u hiermee heeft ingestemd, kunnen wij u informatie sturen over onze producten en diensten.</li>
              </ul>
              <h3 className="text-xl font-semibold mt-4">5. Delen van uw gegevens</h3>
              <p>
                Wij delen uw persoonsgegevens niet met derden, tenzij dit nodig is om onze diensten te leveren, te voldoen aan wettelijke verplichtingen, of indien u hiervoor expliciete toestemming hebt gegeven. Mogelijke ontvangers zijn:
              </p>
              <ul className="list-disc pl-5">
                <li>Betalingsverwerkers: voor het afhandelen van betalingen.</li>
                <li>Dienstverleners: die ons helpen bij het beheren van onze website en het leveren van diensten.</li>
                <li>Overheidsinstanties: indien wettelijk verplicht.</li>
              </ul>
              <h3 className="text-xl font-semibold mt-4">6. Hoe beschermen wij uw gegevens?</h3>
              <p>
                Wij nemen passende technische en organisatorische maatregelen om uw persoonsgegevens te beschermen tegen ongeoorloofde toegang, verlies, vernietiging of wijziging. Dit omvat het gebruik van versleuteling, beveiligde servers en beperkte toegang tot uw gegevens.
              </p>
              <h3 className="text-xl font-semibold mt-4">7. Uw rechten</h3>
              <p>
                Onder de AVG hebt u verschillende rechten met betrekking tot uw persoonsgegevens:
              </p>
              <ul className="list-disc pl-5">
                <li>Recht op inzage: u hebt het recht om te weten welke gegevens wij over u hebben.</li>
                <li>Recht op correctie: u kunt ons vragen om onjuiste of verouderde gegevens te corrigeren.</li>
                <li>Recht op verwijdering: u kunt verzoeken om uw persoonsgegevens te verwijderen.</li>
                <li>Recht op beperking van verwerking: u kunt vragen om de verwerking van uw gegevens te beperken.</li>
                <li>Recht op gegevensoverdraagbaarheid: u hebt het recht om uw gegevens te ontvangen in een gestructureerd, gangbaar en machineleesbaar formaat.</li>
                <li>Recht van bezwaar: u kunt bezwaar maken tegen de verwerking van uw gegevens voor bepaalde doeleinden, zoals direct marketing.</li>
              </ul>
              <p>
                Om deze rechten uit te oefenen, kunt u contact met ons opnemen via de contactgegevens onderaan dit beleid.
              </p>
              <h3 className="text-xl font-semibold mt-4">8. Cookies</h3>
              <p>
                Wij maken gebruik van cookies om uw gebruikservaring te verbeteren en bepaalde functionaliteiten op onze website te bieden. U kunt meer lezen over ons cookiebeleid in ons <a href="#" className="text-blue-500 underline">Cookiebeleid</a>.
              </p>
              <h3 className="text-xl font-semibold mt-4">9. Bewaartermijn</h3>
              <p>
                Wij bewaren uw persoonsgegevens niet langer dan nodig is voor de doeleinden waarvoor ze zijn verzameld, tenzij anders vereist door de wet.
              </p>
              <h3 className="text-xl font-semibold mt-4">10. Wijzigingen in dit privacybeleid</h3>
              <p>
                Wij behouden ons het recht voor om dit privacybeleid op elk moment te wijzigen. Eventuele wijzigingen zullen op deze pagina worden gepubliceerd, en wij raden u aan deze regelmatig te controleren.
              </p>
              <h3 className="text-xl font-semibold mt-4">11. Contactinformatie</h3>
              <p>
                Als u vragen of zorgen heeft over dit privacybeleid, of als u uw rechten wilt uitoefenen, kunt u contact met ons opnemen via:
              </p>
              <ul className="list-disc pl-5">
                <li><strong>E-mailadres:</strong> eeaservices@outlook.com</li>
                <li><strong>Adres:</strong> Zeelandstraat 72, 2660 Antwerpen</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicyPopup;
