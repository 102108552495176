import React, { useState } from 'react';

const TermsAndConditionsPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button
        onClick={togglePopup}
        className="text-white dark:text-gray-400 font-medium text-left hover:underline"
      >
        Algemene Voorwaarden
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-12">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg relative overflow-auto max-h-full">
            <button
              onClick={togglePopup}
              className="text-4xl absolute top-2 right-2 text-gray-500 hover:text-gray-800"
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold mb-4">Algemene Voorwaarden</h2>
            <div className="text-gray-700 space-y-4">
              <p><strong>Ingangsdatum:</strong> 22/08/2024</p>
              <p>
                Deze algemene voorwaarden (de "Voorwaarden") regelen uw gebruik van onze website en diensten. Door gebruik te maken van onze website of diensten, stemt u in met deze Voorwaarden. Lees ze aandachtig door voordat u onze diensten gebruikt.
              </p>
              
              <h3 className="text-xl font-semibold mt-4">1. Toepasselijkheid</h3>
              <p>
                Deze Voorwaarden zijn van toepassing op alle aanbiedingen, overeenkomsten en andere rechtsbetrekkingen waarbij wij goederen of diensten aan u leveren.
              </p>
              
              <h3 className="text-xl font-semibold mt-4">2. Gebruiksvoorwaarden van de Website</h3>
              <p>
                U stemt ermee in om onze website alleen te gebruiken voor wettige doeleinden en op een manier die geen inbreuk maakt op de rechten van anderen of het gebruik en genot van de website door anderen belemmert.
              </p>
              
              <h3 className="text-xl font-semibold mt-4">3. Bestellingen en Betalingen</h3>
              <p>
                Alle bestellingen die via onze website worden geplaatst, zijn onderworpen aan de beschikbaarheid van de producten en aan onze acceptatie van uw bestelling. Wij behouden ons het recht voor om elke bestelling zonder opgave van reden te weigeren.
              </p>
              
              <h3 className="text-xl font-semibold mt-4">4. Levering van Diensten</h3>
              <p>
                Wij streven ernaar om onze diensten binnen de aangegeven termijnen te leveren, maar geven geen garanties over de exacte levertijd. Vertragingen kunnen optreden door onvoorziene omstandigheden.
              </p>
              
              <h3 className="text-xl font-semibold mt-4">5. Intellectuele Eigendom</h3>
              <p>
                Alle inhoud op onze website, inclusief maar niet beperkt tot tekst, afbeeldingen, logo's en software, is ons eigendom of het eigendom van onze licentiegevers en is beschermd door auteursrecht en andere intellectuele eigendomsrechten.
              </p>
              
              <h3 className="text-xl font-semibold mt-4">6. Beperking van Aansprakelijkheid</h3>
              <p>
                Voor zover wettelijk toegestaan, sluiten wij alle aansprakelijkheid uit voor enige directe, indirecte of gevolgschade die voortvloeit uit of verband houdt met uw gebruik van onze website of diensten.
              </p>
              
              <h3 className="text-xl font-semibold mt-4">7. Wijzigingen van de Voorwaarden</h3>
              <p>
                Wij behouden ons het recht voor om deze Voorwaarden op elk moment te wijzigen. Wijzigingen zullen van kracht zijn vanaf het moment dat ze op onze website worden gepubliceerd.
              </p>
              
              <h3 className="text-xl font-semibold mt-4">8. Toepasselijk Recht</h3>
              <p>
                Op deze Voorwaarden is het Belgisch recht van toepassing. Eventuele geschillen zullen worden voorgelegd aan de bevoegde rechtbanken in België.
              </p>
              
              <h3 className="text-xl font-semibold mt-4">9. Contactinformatie</h3>
              <p>
                Als u vragen heeft over deze Voorwaarden, kunt u contact met ons opnemen via:
              </p>
              <ul className="list-disc pl-5">
                <li><strong>E-mailadres:</strong> eeaservices@outlook.com </li>
                <li><strong>Adres:</strong> Zeelandstraat 72
                2660 Antwerpen</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsAndConditionsPopup;
