import React, { useState } from 'react';

const CookiePolicyPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button
        onClick={togglePopup}
        className="text-white dark:text-gray-400 font-medium text-left hover:underline"
      >
        Cookiebeleid
      </button>

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-12">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg relative overflow-auto max-h-full">
            <button
              onClick={togglePopup}
              className=" text-4xl absolute top-2 right-2 text-gray-500 hover:text-gray-800"
            >
              &times;
            </button>
            <h2 className="text-2xl font-bold mb-4">Cookiebeleid</h2>
            <div className="text-gray-700 space-y-4">
              <p><strong>Ingangsdatum:</strong> 22/08/2024</p>
              <p>
                Dit cookiebeleid legt uit wat cookies zijn, hoe wij ze gebruiken, en hoe u ze kunt beheren wanneer u onze website bezoekt.
              </p>

              <h3 className="text-xl font-semibold mt-4">1. Wat zijn cookies?</h3>
              <p>
                Cookies zijn kleine tekstbestanden die door een website op uw apparaat worden geplaatst wanneer u de site bezoekt. Ze worden veel gebruikt om websites te laten werken, of efficiënter te laten werken, en om informatie aan de eigenaars van de site te verstrekken.
              </p>

              <h3 className="text-xl font-semibold mt-4">2. Hoe gebruiken wij cookies?</h3>
              <p>
                Wij gebruiken cookies om verschillende redenen, zoals om onze website goed te laten functioneren, om uw voorkeuren te onthouden, en om het gebruik van onze site te analyseren zodat wij deze kunnen verbeteren. Wij gebruiken zowel sessiecookies (die worden verwijderd wanneer u uw browser sluit) als permanente cookies (die op uw apparaat blijven totdat ze worden verwijderd).
              </p>

              <h3 className="text-xl font-semibold mt-4">3. Soorten cookies die wij gebruiken</h3>
              <p>Wij kunnen de volgende soorten cookies gebruiken:</p>
              <ul className="list-disc pl-5">
                <li><strong>Noodzakelijke cookies:</strong> Deze cookies zijn essentieel om u in staat te stellen door onze website te navigeren en de functies ervan te gebruiken.</li>
                <li><strong>Prestatiecookies:</strong> Deze cookies verzamelen informatie over hoe bezoekers onze website gebruiken, zoals welke pagina's het meest worden bezocht.</li>
                <li><strong>Functionaliteitscookies:</strong> Deze cookies onthouden keuzes die u maakt om uw gebruik van de site te verbeteren.</li>
                <li><strong>Targeting- en advertentiecookies:</strong> Deze cookies worden gebruikt om advertenties te leveren die relevant zijn voor u en uw interesses.</li>
              </ul>

              <h3 className="text-xl font-semibold mt-4">4. Beheer van cookies</h3>
              <p>
                U kunt cookies beheren via de instellingen van uw browser. U kunt uw browser zo instellen dat cookies worden geweigerd of dat u wordt gewaarschuwd wanneer een cookie wordt geplaatst. Als u ervoor kiest om cookies uit te schakelen, kunnen sommige delen van onze website mogelijk niet goed functioneren.
              </p>

              <h3 className="text-xl font-semibold mt-4">5. Wijzigingen in ons cookiebeleid</h3>
              <p>
                Wij kunnen dit cookiebeleid van tijd tot tijd bijwerken om veranderingen in onze werkwijzen of om andere operationele, wettelijke of regelgevende redenen weer te geven. Wij raden u aan deze pagina regelmatig te raadplegen voor de laatste informatie over ons cookiebeleid.
              </p>

              <h3 className="text-xl font-semibold mt-4">6. Contactinformatie</h3>
              <p>
                Als u vragen heeft over ons gebruik van cookies of dit cookiebeleid, kunt u contact met ons opnemen via:
              </p>
              <ul className="list-disc pl-5">
                <li><strong>E-mailadres:</strong> eeaservices@outlook.com</li>
                <li><strong>Adres:</strong> Zeelandstraat 72
                2660 Antwerpen</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CookiePolicyPopup;
