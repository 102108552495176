import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Controleer of de gebruiker al akkoord is gegaan met cookies
    const hasAcceptedCookies = localStorage.getItem('cookiesAccepted');
    if (!hasAcceptedCookies) {
      setIsVisible(true);
    }
  }, []);

  const handleAcceptCookies = () => {
    // Sla de keuze van de gebruiker op in localStorage
    localStorage.setItem('cookiesAccepted', true);
    setIsVisible(false);
  };

  if (!isVisible) {
    return null; // Als de banner niet zichtbaar is, render dan niets
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-green-400 text-white p-4 flex items-center justify-between z-50">
      <p className="text-sm">
        Wij gebruiken cookies om uw ervaring te verbeteren. Door deze site te blijven gebruiken, gaat u akkoord met ons gebruik van cookies.
      </p>
      <button
        onClick={handleAcceptCookies}
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-gray-900"
      >
        Akkoord
      </button>
    </div>
  );
};

export default CookieBanner;
